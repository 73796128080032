<template>
  <v-app>
    <Navigation />
    <v-main>
      <CommonCard>
        <div style="padding:35px">
          <span class="titulo-header">Chats clientes</span>
        </div>
        <div style="padding-left: 30px; padding-right: 30px;">
          <v-row style="align-items: end; text-align: end;">
            <v-col cols="12">
              <v-btn
                    class="common-botton"
                    dark
                    :loading="loading"
                    @click="openLayoutModal = true"
                    style="margin-right: 10px;"
                  >
                    Subír layout
                  </v-btn>
            </v-col>
          </v-row>
        </div>
        <ChatsFiltros
          :canalList="canalList"
          :ramoList="ramoList"
          :campaniaList="campaniaList"
          :disposicionesList="disposicionesList"
          :loading="loading"
          :operadoresList="operadoresList"
          :cleanFilters="cleanFilters"
          @updateTipoServicio="updateTipoServicio"
          @cleanLeads="
            () => {
              (chatsLeads = []), (cleanFilters = false);
            }
          "
          @updateSearch="updateSearch"
          @templateUpdate="(e) => (idTemplateChat = e)"
        />
        <ChatsResultados
          :loading="loading"
          :chatsLeads="chatsLeads"
          :operadoresList="operadoresList"
          :campaniaList="campaniaList"
          :currentFilters="currentFilters"
          @goToChats="goToChats"
        />
        <CommondLoading
          :loadingImage="dataLoading.inter"
          :show="loadingModal"
          loadingText="Enviando chats..."
        />
        <CommonLayoutModal :dialog="openLayoutModal" @userChoice="((e) =>{openLayoutModal = e})"/>
        </CommonCard>
    </v-main>
    <v-footer style="text-align:center;">
      <p style="text-align:center;width:100%; margin: 10px 0px">Segurify {{ year }} <a target="_blank" href="/terminos-y-condiciones">Terminos y condiciones</a> y <a target="_blank" href="/aviso-de-privacidad">Aviso de privacidad</a>  </p>
    </v-footer>
  </v-app>
</template>

<script>
import moment from "moment";
import ChatsFiltros from "./ChatsFiltros.vue";
import ChatsResultados from "./ChatsResultados.vue";
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import { mainAxios } from "@/mainAxios.js";
import CommondLoading from "@/components/commonComponents/CommondLoading.vue";
import dataLoading from "../../../../helpers/data/data-loading-gif.json";
import Navigation from "../../../../components/Nav.vue";
import CommonLayoutModal from "@/components/commonComponents/CommonLayoutModal.vue";

export default {
  components: {
    ChatsFiltros,
    CommonCard,
    ChatsResultados,
    CommondLoading,
    Navigation,
    CommonLayoutModal
  },
  data() {
    return {
      roles: JSON.parse(localStorage.getItem("roles")),
      canalList: [],
      ramoList: [],
      campaniaList: [],
      disposicionesList: [],
      operadoresList: [],
      chats_leads: [],
      loading: false,
      tipo_servicio: null,
      chatsLeads: [],
      idTemplateChat: "",
      currentFilters: {},
      dataLoading: dataLoading,
      loadingModal: false,
      cleanFilters: false,
      year:moment().year(),
      openLayoutModal:false
    };
  },

  methods: {
    listas() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/canal/list`, config).then((response) => {
        this.canalList = response.data;
      });
      mainAxios.get(`/v1/ramo/list`, config).then((response) => {
        this.ramoList = response.data;
      });
      mainAxios.get("/v1/campania-cliente/list", config).then((response) => {
        response.data.map((element) => {
          if (element.contactoChat)
            this.campaniaList.push({
              id: element.id ?? "",
              producto: element.producto ?? "",
              ramo: element.ramo ?? null,
              canal: element.canal ?? null,
              intentos: element.intentos,
              intentosChat: element.intentos,
              idTemplateChat: element.idTemplateChat,
              appId: element.appId,
              servicioChat: element.servicioChat ?? null,
              didInbound: element.didInbound ?? null,
            });
        });
      });
      mainAxios.get("/v1/disposiciones/list", config).then((response) => {
        response.data.map((element) => {
          this.disposicionesList.push({
            id: element.id,
            disposicion: element.disposicion,
            tipo_contacto: element.tipoContacto,
          });
        });
      });
      mainAxios.get("/v1/agentes/operador", config).then(({ data }) => {
        this.operadoresList = data.map((e) => ({
          correo: e.correo,
          nombreCompleto: e.nombreCompleto,
          id: e.id,
          uuid: e.uuid,
        }));
      });
    },
    updateSearch(data) {
      this.loading = true;
      this.chatsLeads = [];
      this.currentFilters = data.form;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .post(
          `/v1/origencliente/filtros?tipo-servicio=${data.tipo_servicio}`,
          data.form,
          config
        )
        .then((response) => {
          response.data.map((e) => {
            var jsonRequest = JSON.parse(e.requestJson);
            this.chatsLeads.push({
              chat: false,
              fecha_lead: e.fecha_lead
                ? moment(e.fecha_lead, "YYYY-MM-DD hh:mm:ss")
                    .subtract(6, "hours")
                    .format("DD/MM/YYYY hh:mm:ss")
                : "No disponible",
              medio: e.medio ?? "No disponible",
              nombre: `${jsonRequest.nombre ||
                ""} ${jsonRequest.apellidoPaterno ||
                ""} ${jsonRequest.apellidoMaterno || ""}`,
              telefono: e.telefono ?? "No disponible",
              status_llamada: e.status ?? "No disponible",
              disposicion: e.disposicion ?? "No disponible",
              operador_id: e.operador_id ?? null,
              intentos: e.intentos ?? "0",
              statusChat: e.statusChat ?? "Sin estatus",
              ultimaDisposicionChat:
                e.ultimaDisposicionChat ?? "Sin disposicion",
              intentosChat: e.intentosChat ?? "0",
              servicioChat: e.servicioChat ?? "0",
              didInbound: e.didInbound ?? "0",
              uuid_lead_segurify: e.uuid_lead_segurify ?? "0",
              uuid: e.uuid ?? "0",
              tipo_servicio: data.tipo_servicio,
              jsonRequest: e.requestJson ?? "",
            });
          });
          this.loading = false;
        });
    },
    updateTipoServicio(val) {
      this.tipo_servicio = val;
    },
    goToChats(data) {
      this.loadingModal = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .post(`/v1/chats_clientes/insert/twilio`, data, config)
        .then(() => {
          this.loadingModal = false;
          this.successModal();
          this.cleanFilters = true;
        })
        .catch((error) => {
          this.loadingModal = false;
          this.errorModal(error);
        });
    },
    errorModal(error) {
      this.$toast.error("Lo sentimos ha ocurrido un error:  " + error, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    successModal() {
      this.$toast.success("Chats eviados con exito", {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.listas();
  },
};
</script>
<style scoped>
@import url(../../../../css/global-styles.css);
</style>
