<template>
    <div>
      <v-form
          ref="commondForm"
          :lazy-validation="true"
      >
        <slot name="data"></slot>
        </v-form>
        <CommonConfirmationModal :dialog="dialog" @userChoice="userChoice"></CommonConfirmationModal>
    </div>
</template>

<script>
import { mainAxios } from '@/mainAxios.js';
import CommonConfirmationModal from './CommonConfirmationModal.vue';

export default {
  components: {
    CommonConfirmationModal
  },
  props: {
    route: String,
    request: Object,
    run: Boolean,
    onlyValidations:{
      type: Boolean,
      default: false,
    },
    action: String,
  },

  data() {
    return {
      config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        }
      },
      dialog:false,
    }
  },
  watch:{
    run(val){
      if(val){
        this.validate()
      }
    }

  },
  methods: {
    userChoice(val){
      if(val){
        switch(this.action){
          case 'POST':
              this.sendPost()
            break;
          case 'PUT':
            this.sendPut()
            break;
          default:
            break;
        }
      }
      else
        this.$emit('cancel')

      this.dialog = false;        

    },
    sendPost(){
      mainAxios.post(this.route, this.request, this.config)
        .then(response => {
          if (response.status == 200) {
            this.$emit('success', response)
            this.$emit('cancel')
            this.successModal()
          } else {
            if(response.data.status == 400)
              if(response.data.message)
                  this.errorModal(response.data.message)
              else
                this.errorModal(response.message)
            else
              this.errorModal(response.message)

            this.$emit('afterError', response)
            this.$emit('cancel') 
        }})
        .catch(error => {
          if (error.response) this.errorModal(error.response.data.message);
          else if (error) {
            this.$emit('afterError', error)
            this.errorModal(error)
            this.$emit('cancel')
          }
        })
    },
    sendPut(){
      mainAxios.put(this.route, this.request, this.config)
        .then(response => {
          if (response.status == 200) {
            this.$emit('success', response)
            this.$emit('cancel')
            this.successModal()
          } else {
            if(response.data.status == 400)
              if(response.data.message)
                  this.errorModal(response.data.message)
              else
                this.errorModal(response.message)
            else
              this.errorModal(response.message)
            
            this.$emit('afterError', response)
            this.$emit('cancel') 
        }})
        .catch(error => {
          if (error) {
            this.$emit('afterError', error)
            this.errorModal(error)
            this.$emit('cancel')
          }
        })
    },
    validate () {
      if(this.$refs.commondForm.validate())
        if(this.onlyValidations){
          this.$emit('afterValidation', this.$refs.commondForm.validate())
          this.$emit('cancel')
        }else
          this.dialog = true;
      else  
        this.$emit('cancel')
    },
    reset () {
      this.$refs.commondForm.reset()
    },
    resetValidation () {
      this.$refs.commondForm.resetValidation()
    },
    es400(){

    },
    errorModal(error){
      this.$toast.error("Lo sentimos ha ocurrido un error:  "+ error, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
    successModal(){
      this.$toast.success("Accion realizada con exito ! ", {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    }
  }
};
</script>