<template>
  <v-dialog v-model="dialog" persistent width="700">
    <common-card>
      <v-card-text class="pa-4 black--text" style="text-align: center;">
        <div
          class="pt-5 pb-5 top-text"
          style="width: 100%; height: 100%; justify-content: center;"
        >
          Carga de registros
        </div>
        <div class="pt-5 pb-5">
          <span class="confirmation-text">
            Por favor, asegúrate de revisar cuidadosamente <br />
            los cambios antes de continuar
          </span>
        </div>
        <div>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="campaña"
                label="Selecciona la campaña"
                :items="campañas"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-file-input
                small-chips
                outlined
                v-model="file"
                label="Seleccione el layout"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                class="common-botton"
                dark
                @click="handleFileUpload()"
                :disabled="!file && campañas != ''"
                :loading="loading"
                >Crear registros</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                text
                color="#00a7e4"
                outlined
                dark
                style="border-radius: 15px;"
                @click="userChoice(false)"
              >
                Cancelar</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </common-card>
    <CommonActionModal
      :dialog="dialogConfirmarEnvio"
      :title="titleModal"
      :subtitle="subtitleModal"
      :bottonText="bottonText"
      @userChoice="enviarChats"
    />
    <CommondLoading
      :loadingImage="dataLoading.inter"
      :show="loading"
      :loadingText="
        'Enviando registro: ' + chatsCargadosConExito + ' de ' + chatsTotales
      "
    />
  </v-dialog>
</template>

<script>
import CommonCard from "./CommonCard.vue";
import * as XLSX from "xlsx";
import CommonActionModal from "@/components/commonComponents/CommonActionModal.vue";
import { mainAxios } from "@/mainAxios.js";
import CommondLoading from "@/components/commonComponents/CommondLoading.vue";
import dataLoading from "../../helpers/data/data-loading-gif.json";

export default {
  components: {
    CommonCard,
    CommonActionModal,
    CommondLoading,
  },
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      file: null,
      origenesCliente: [],
      campaña: "",
      campañas: ["Renovaciones Coche Seguro", "Cobranza Coche Seguro"],
      renovacionesLayoutCampos: [
        "Poliza anterior",
        "Poliza nueva",
        "Contratante",
        "Telefono",
        "Email contratante",
        "INTERPROTECCIÓN",
        "ASEGURADORA",
        "Inicio de vigencia de póliza",
        "Fin de vigencia de póliza",
        "Vehicle_description",
        "Url_pol",
        "Serie de vehículo",
      ],
      cobranzaLayoutCampos: [
        "Póliza",
        "Aseguradora",
        "Cliente",
        "IniRecibo",
        "Telefono",
        "Mail",
        "Version"
      ],
      chatsCargadosConExito: 0,
      chatsFallidos: 0,
      dialogConfirmarEnvio: false,
      titleModal: "",
      subtitleModal: "",
      bottonText: "",
      loading: false,
      dataLoading: dataLoading,
      chatsTotales: 0,
    };
  },
  methods: {
    handleFileUpload() {
      this.origenesCliente = [];
      const file = this.file;
      if (!file) {
        console.error("No se ha seleccionado ningún archivo.");
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const data = new Uint8Array(reader.result);
        const workbook = XLSX.read(data, { type: "array" });
        if (!workbook.SheetNames || workbook.SheetNames.length === 0) {
          console.error("No se encontraron hojas en el archivo XLSX.");
          return;
        }
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        if (!sheet) {
          console.error(
            "No se encontró la hoja especificada en el archivo XLSX."
          );
          return;
        }
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const keys = jsonData.shift();
        const formattedData = [];
        var missKey = false;
        missKey = this.checkLayout(keys)

        if (!missKey) {
          jsonData.forEach((row) => {
            const obj = {};
            keys.forEach((key, index) => {
              if (
                key === "Inicio de vigencia de póliza" ||
                key === "Fin de vigencia de póliza" ||
                key === "IniRecibo" 
              ) {
                const serialDate = parseFloat(row[index]);
                const date = new Date((serialDate - 25569) * 86400 * 1000);
                obj[key] = date;
              } else {
                obj[key] = row[index];
              }
            });
            formattedData.push(obj);
          });
          formattedData.forEach((e) => {
            switch (this.campaña) {
              case "Renovaciones Coche Seguro":
                this.origenesCliente.push({
                  medio: "Renovaciones Coche Seguro" ?? "",
                  poliza_anterior: e["Poliza anterior"].toString() ?? "",
                  poliza_nueva: e["Poliza nueva"].toString() ?? "",
                  nombre: e["Contratante"] ?? "",
                  apellido_paterno: "",
                  apellido_materno: "",
                  rfc: "",
                  telefono: e["Telefono"].toString() ?? "",
                  email: e["Email contratante"] ?? "",
                  tipo_persona: "Física" ?? "",
                  origen: e["INTERPROTECCIÓN"] ?? "",
                  aseguradora: e["ASEGURADORA"] ?? "",
                  inicio_vigencia:
                    this.formatDate(
                      new Date(e["Inicio de vigencia de póliza"])
                    ) ?? "",
                  fin_vigencia:
                    this.formatDate(new Date(e["Fin de vigencia de póliza"])) ??
                    "",
                  vehiculo: e["Vehicle_description"] ?? "",
                  url_poliza: e["Url_pol"] ?? "",
                  url_pago: e["Url_pago"] ?? "",
                  serie: e["Serie de vehículo"] ?? "",
                });
                break;
              case "Cobranza Coche Seguro":
                this.origenesCliente.push({
                  medio: "Cobranza Coche Seguro",
                  poliza: String(e["Póliza"]) ?? "",
                  nombre: e["Cliente"] ?? "",
                  vehiculo: e["Version"] ?? "",
                  fecha_pago: this.formatDate(new Date(e["IniRecibo"])) ?? "",
                  email: String(e["Mail"]) ?? "",
                  apellido_paterno: "",
                  apellido_materno: "",
                  rfc: "",
                  telefono: String(e["Telefono"]) ?? "",
                  tipo_persona: "Física" ?? "",
                  origen: "INTERPROTECCIÓN",
                  aseguradora: e["ASEGURADORA"] ?? "",
                });
                break;
            }
          });
          this.chatsTotales = this.origenesCliente.length;
          this.dialogConfirmarEnvio = true;
          this.titleModal =
            "¿Quieren enviar " + this.origenesCliente.length + " registros?";
          this.subtitleModal =
            "Si confirmas el envio estos registros estan disponibles para el envio de chats masivos en la campaña: " +
            this.campaña;
          this.bottonText = "Enviar";
        } else {
          this.errorModal(
            "El archivo que cargaste no coincide con el layout requerido para la campaña seleccionada"
          );
        }
      };
      reader.readAsArrayBuffer(file);
    },
    formatDate(date) {
      const day = date
        .getDate()
        .toString()
        .padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    userChoice(val) {
      this.$emit("userChoice", val);
    },
    errorModal(error) {
      this.$toast.error(error, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      this.file = null;
    },
    infoModal(mensagge) {
      this.$toast.info(mensagge, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      this.file = null;
    },
    async enviarChats(val) {
      this.dialogConfirmarEnvio = false;
      this.chatsCargadosConExito = 0;
      this.chatsFallidos = 0;

      if (val) {
        this.loading = true;
      }

      if (this.origenesCliente.length > 0) {
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };

        for (const e of this.origenesCliente) {
          try {
            await mainAxios.post("/v1/cliente-inter", e, config);
            this.chatsCargadosConExito++;
          } catch (error) {
            this.chatsFallidos++;
          }
        }

        this.file = null;
        this.loading = false;
        if (this.chatsCargadosConExito > 0)
          this.infoModal(
            "Se enviaron con éxito: " +
              this.chatsCargadosConExito +
              " registros."
          );
        if (this.chatsFallidos > 0)
          this.infoModal(
            "Fallaron: " +
              this.chatsFallidos +
              " registros.                     "
          );
      } else {
        this.errorModal("Parece que el layout no tiene registros para leer");
      }
    },
    checkLayout(keys){
      var missKey = false
      switch(this.campaña){
        case 'Renovaciones Coche Seguro':
            this.renovacionesLayoutCampos.map((e) => {
              if (!keys.includes(e)) missKey = true;
            });
          break;
            case 'Cobranza Coche Seguro':
            this.cobranzaLayoutCampos.map((e) => {
              if (!keys.includes(e)) missKey = true;
            });
          break;
      }
      return missKey
    }
  },
};
</script>

<style>
.v-toolbar--dense .v-toolbar__content,
.v-toolbar--dense .v-toolbar__extension {
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
}

.top-text {
  width: 369px;
  height: 22px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4d5358;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.confirmation-text {
  /* Por favor, asegúrate de revisar cuidadosamente los cambios antes de continuar */

  width: 369px;
  height: 30px;

  /* Subtitle/S */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  /* or 15px */
  text-align: center;

  color: #555555;

  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}
</style>
