<template>
    <v-dialog  v-model="show" persistent max-width="290">
      <v-card style="padding:32px">
        <v-card-text class="text-center">
          <v-img :src="loadingImage" contain max-height="150" max-width="250"></v-img>
          <div><h2>{{ loadingText }}</h2></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: "LoadingModal",
    props: {
      loadingImage: {
        type: String,
        required: true,
        default: ""  
      },
      loadingText: {
        type: String,
        required: true,
        default: "Cargando..."
      },
      show: {
        type: Boolean,
        required: true,
        default: true
      }
    }
  };
  </script>
  
  <style scoped>
  </style>